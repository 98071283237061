var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: _vm.__getText('global', 'createNewText'),
    key: 'create'
  },"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.showChart)?_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading || _vm.makingApiRequest),expression:"loading || makingApiRequest"}],key:_vm.key,attrs:{"hideAllFilters":false,"element-loading-text":_vm.loadingText,"data":_vm.getBillingChangeRequestsFromStore,"totalRecords":_vm.getTotalRecords,"tableActions":_vm.tableActions,"tableRowActions":_vm.tableRowActions,"addAdvancedFilter":true,"tableColumns":_vm.tableColumns,"bulkMenuActions":_vm.bulkMenuActionsData,"hideToggleIcons":true,"paginationLinks":_vm.getBillingChangeRequestsLinks,"maxQuerySize":_vm.getMaxQuerySize,"showSelectable":true,"filters":_vm.filters,"customBulkMenuActions":_vm.customBulkMenuActions,"customBulkActionsMenuTitle":`Change Status to`,"rowKey":"_id","reserveSelection":_vm.reserveSelection,"targetedSearch":true,"searchColumnName":"All","targetedSearchData":[
      { key: 'All', value: '' },
      { key: 'First Name', value: 'data.insured.firstName' },
      { key: 'Last Name', value: 'data.insured.lastName' },
      {
        key: 'Agent Code',
        value: 'data.policyInfo.agencyCode'
      },
      { key: 'Agent', value: 'submittedBy' },
      { key: 'Policy Number', value: 'data.policyInfo.policyNumber' },
      { key: 'Status', value: 'status' }
    ]},on:{"table-row-action":_vm.handleResponseAction,"emitEvent":_vm.handleResponseAction,"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewChangeRequest,"table-action":_vm.tableActionHandler,"custom-bulk-action-event":_vm.customBulkActionHandler,"selection-change":function($event){_vm.selectedDocuments = $event},"bulk-action-event":_vm.bulkActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","image":_vm.billingChangeRequestNotFoundImage,"message":"No Billing Change Requests Found."},slot:"notFound"})],1):(_vm.showChart)?_c('Charts',{attrs:{"fitParent":['width'],"chart":_vm.chart,"allowFullScreen":true}}):_vm._e(),_c('ModalBase',{attrs:{"name":"exportBillingChangeRequestsModal","size":"medium","clickToClose":false,"showClose":true,"title":"Export Cancellation Change Requests","loading":_vm.makingApiRequest,"position":"center"}},[_c('Exporter',{attrs:{"defaultType":"billingChangeRequests"},on:{"exported":function($event){return _vm.$modal.hide('exportBillingChangeRequestsModal')}}})],1),_c('ModalMessage',{attrs:{"name":"billingChangeRequestDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will delete the change request. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneBillingChangeRequest()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('billingChangeRequestDeleteModal')
      }
    ]}}),_c('div',{staticClass:"h-20"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }