import * as selectOptions from "@/helpers/selectOptions";
import { formatAgencyCode } from "@/helpers/generalHelpers";

export const tableColumnsListBillingChangeRequests = {
  "data.insured.firstName": {
    formatDataKey: "First Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0,
    minWidth: "120px"
  },
  "data.insured.lastName": {
    formatDataKey: "Last Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    minWidth: "120px"
  },
  "data.policyInfo.agencyCode": {
    formatDataKey: "Agt Code",
    formatDataValue: (arg: string) => (arg ? formatAgencyCode(arg) : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    minWidth: "110px"
  },
  submittedBy: {
    formatDataKey: "Agent",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 3,
    minWidth: "155px"
  },
  modifiedOnFormatted: {
    formatDataKey: "Chg Date",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    minWidth: "110px"
  },
  submittedOnFormatted: {
    formatDataKey: "Submitted",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 5,
    minWidth: "120px"
  },
  "data.policyInfo.policyNumber": {
    formatDataKey: "Policy #",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 6,
    minWidth: "100px"
  },
  status: {
    formatDataKey: "status",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.policyStatus,
    position: 7,
    minWidth: "110px"
  },
  printed: {
    formatDataKey: "Printed",
    formatDataValue: (arg: string) => (arg ? "Yes" : "No"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 8,
    minWidth: "80px"
  }
};
