
import {
  billingChangeRequestMapActions,
  billingChangeRequestMapGetters,
  billingChangeRequestMapState
} from "@/store/modules/billingchangerequest";
import { tableColumnsListBillingChangeRequests } from "./TableColumns";
import BillingChangeRequestTableActionsVue from "../../ChangeRequestTableActions/ChangeRequestTableActions.vue";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import Charts from "@/components/Charts/Charts.vue";
import { get } from "lodash";
import ChangeRequestMixin from "@/components/ChangeRequests/ChangeRequestMixin";
import { clearSelectionsNotInData, getSystemFilesURL } from "@/helpers";
import VueWithMixins from "@/helpers/mixins";

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "BillingChangeRequestsDashboard",
  components: {
    Exporter: () => import("@/components/Exporter/Exporter.vue"),
    Charts,
    NoResultsFound
  },
  data() {
    return {
      tableColumns: tableColumnsListBillingChangeRequests,
      pageKey: "Cancellation",
      exportModalKey: "exportBillingChangeRequestsModal",
      deleteModalKey: "billingChangeRequestDeleteModal",
      activityRoute: "/admin/billingchangerequests/activities",
      changeRequestRoute: "billingchangerequest"
    };
  },
  methods: {
    // get store methods
    ...billingChangeRequestMapActions([
      "deleteBillingChangeRequest",
      "printBillingChangeRequest",
      "getBillingChangeRequests",
      "updateBillingChangeRequestsStatus",
      "deleteBulkChangeRequests",
      "restoreBillingChangeRequests"
    ]),
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Print Selected": {
          try {
            this.loading = true;
            this.loadingText = "Printing billing change request...";
            let message = "Only valid billing change requests will be printed";
            let messageType = "info";
            const documentIds: string[] = this.getSelectedDocumentIds;

            if (documentIds.length == 0) {
              message = "No billing change request selected";
              messageType = "error";
            }
            this.$appNotify({
              title: "Please note:",
              type: messageType,
              message: message,
              offset: 100,
              position: "top-right"
            });

            await this.printCallback(
              documentIds,
              this.printBillingChangeRequest,
              "billing"
            );
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        }
        case "Delete Selected": {
          try {
            this.loading = true;
            this.loadingText = "Deleting billing change request...";
            const ids = this.selectedDocuments.map((doc: any) => doc._id);
            await this.deleteBulkChangeRequests(ids);
            this.$appNotifySuccess("Selected Requests Deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
          }
          break;
        }
        case "Restore Selected": {
          try {
            this.loadingText = "Restoring selected change requests...";
            const ids = this.selectedDocuments.map((doc: any) => doc._id);
            await this.restoreBillingChangeRequests({ ids });
            this.$appNotifySuccess("Selected Requests Restored");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      data.isLegacy = this.queryOverride.isLegacy;
      const policyPrefix =
        data &&
        data.data &&
        data.data.policyInfo &&
        data.data.policyInfo.policyPrefix
          ? data.data.policyInfo.policyPrefix
          : undefined;

      switch (payload.name) {
        case "print":
          this.printCallback(
            data._id,
            this.printBillingChangeRequest,
            "billing",
            policyPrefix
          );
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "view":
          this.viewChangeRequest(data);
          break;
        case "edit":
          if (
            (data.status === "Not Submitted" ||
              (get(this.getCurrentUser, "role") === "admin" &&
                get(data, "status") !== "Received" &&
                !get(data, "printed"))) &&
            !data.isLegacy
          ) {
            this.$router
              .push(`/billingchangerequest/edit/${data._id}`)
              .catch(() => {});
            break;
          } else {
            this.viewChangeRequest(data);
            break;
          }
        case "restore":
          this.restoreChangeRequests(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async restoreChangeRequests(id: string): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Restoring billing change request...";
        await this.restoreBillingChangeRequests({ ids: [id] });
        this.successMessage = "Claim Successfully Restored";
        this.fetchPageData({});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteOneBillingChangeRequest(): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Deleting billing change request...";
        await this.deleteBillingChangeRequest(this.changeRequestId);
        this.$modal.hide("billingChangeRequestDeleteModal");
        this.$appNotifySuccess("Cancellation Change Request Deleted.");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    getBillingChangeRequestOperations() {
      return BillingChangeRequestTableActionsVue;
    },
    async fetchPageData(queryObject: any): Promise<void> {
      const { __limit: limit, __sort: sort } = queryObject;
      if (!limit) {
        queryObject = {
          ...queryObject,
          __limit: 50
        };
      }
      const sortFormats: Record<string, string> = {
        "-submittedOnFormatted": "-submittedOn",
        submittedOnFormatted: "submittedOn",
        "-modifiedOnFormatted": "-modifiedOn",
        modifiedOnFormatted: "modifiedOn"
      };
      if (sort) {
        queryObject.__sort = sortFormats[sort];
      }
      try {
        this.loading = true;
        this.loadingText = "Fetching billing change requests...";
        this.$emit("scrollTop");
        await this.getBillingChangeRequests({
          query: { ...queryObject, ...this.queryOverride }
        });
        this.selectedDocuments = clearSelectionsNotInData(
          this.getBillingChangeRequestsFromStore,
          this.selectedDocuments
        );
      } catch (error) {
        this.errorMessage =
          "Error fetching cancellation change requests. Try again later";
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.reserveSelection = true;
      }
    },
    async customBulkActionHandler(action: string) {
      this.reserveSelection = false;
      switch (action) {
        case "Not Submitted":
        case "Submitted":
        case "Received":
        case "Issued": {
          try {
            await this.updateStatus(
              action,
              this.updateBillingChangeRequestsStatus
            );
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    }
  },
  computed: {
    ...billingChangeRequestMapGetters([
      "getBillingChangeRequestsFromStore",
      "getBillingChangeRequestsLinks",
      "getTotalRecords",
      "getMaxQuerySize"
    ]),
    billingChangeRequestNotFoundImage() {
      return getSystemFilesURL("billing-change");
    },
    ...billingChangeRequestMapState(["makingApiRequest"]),
    chart(): string {
      return this.getChart("billing-change-status");
    },
    tableActions(): string[] {
      return this.getCurrentUser && this.getCurrentUser.role === "admin"
        ? ["Export Cancellation Change Requests", "All Activities"].sort()
        : [];
    }
  }
});
